var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-line-chart',{attrs:{"labels":[
      'label.monday',
      'label.tuesday',
      'label.wednesday',
      'label.thursday',
      'label.friday',
      'label.saturday',
      'label.sunday' ],"values":[
      _vm.report.mondayRevenue,
      _vm.report.tuesdayRevenue,
      _vm.report.wednesdayRevenue,
      _vm.report.thursdayRevenue,
      _vm.report.fridayRevenue,
      _vm.report.saturdayRevenue,
      _vm.report.sundayRevenue ],"value-type":"amount"}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":[
        {
          day: 'monday',
          dayNumber: 1,
          revenue: _vm.report.mondayRevenue
        },
        {
          day: 'tuesday',
          dayNumber: 2,
          revenue: _vm.report.tuesdayRevenue
        },
        {
          day: 'wednesday',
          dayNumber: 3,
          revenue: _vm.report.wednesdayRevenue
        },
        {
          day: 'thursday',
          dayNumber: 4,
          revenue: _vm.report.thursdayRevenue
        },
        {
          day: 'friday',
          dayNumber: 5,
          revenue: _vm.report.fridayRevenue
        },
        {
          day: 'saturday',
          dayNumber: 6,
          revenue: _vm.report.saturdayRevenue
        },
        {
          day: 'sunday',
          dayNumber: 7,
          revenue: _vm.report.sundayRevenue
        } ],"item-key":"key","item-properties":[
        {
          key: 'day',
          text: function (item) { return _vm.$t('label.' + item.day); },
          value: function (item) { return item.dayNumber; },
          sortable: true
        },
        {
          key: 'revenue',
          type: 'amount',
          header: 'label.amount',
          sortable: true
        }
      ],"default-sort-property-key":"day"}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":_vm.report.products,"item-key":"productName","item-properties":[
        {
          key: 'productName',
          header: 'label.product',
          sortable: true
        },
        {
          key: 'mondayRevenue',
          header: 'label.monday',
          type: 'amount',
          sortable: true
        },
        {
          key: 'tuesdayRevenue',
          header: 'label.tuesday',
          type: 'amount',
          sortable: true
        },
        {
          key: 'wednesdayRevenue',
          header: 'label.wednesday',
          type: 'amount',
          sortable: true
        },
        {
          key: 'thursdayRevenue',
          header: 'label.thursday',
          type: 'amount',
          sortable: true
        },
        {
          key: 'fridayRevenue',
          header: 'label.friday',
          type: 'amount',
          sortable: true
        },
        {
          key: 'saturdayRevenue',
          header: 'label.saturday',
          type: 'amount',
          sortable: true
        },
        {
          key: 'sundayRevenue',
          header: 'label.sunday',
          type: 'amount',
          sortable: true
        } ],"default-sort-property-key":"productName"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }