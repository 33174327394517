var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"default-sort-ascending":false,"item-filtering-handler":_vm.cardSearch.search,"item-properties":[
        {
          key: 'id',
          header: 'label.cardNumber',
          type: 'uuid',
          sortable: true
        },
        {
          key: 'userId',
          header: 'label.username',
          sortable: true
        },
        {
          key: 'name',
          header: 'label.nickname',
          sortable: true
        },
        {
          key: 'disabled',
          type: 'icon',
          icon: function (card) { return card.disabled ? { image: 'mdi-credit-card-off', color: 'error' } : null; },
          sortable: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: function (card) { return _vm.$dialog.openDialog('card', card); }
        }
      ],"items":_vm.cardSearch,"search":true,"default-sort-property-key":"disabled","item-key":"id","no-items-label":"message.noCards"}})}
var staticRenderFns = []

export { render, staticRenderFns }