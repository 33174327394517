var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"subscription","action-chips":[
    {
      icon: 'mdi-lead-pencil',
      label: 'label.invoicingSettings',
      handler: function () { return _vm.$dialog.openDialog('invoicing-settings'); },
      hideHandler: function () { return ! this$1.subscriptionPlanList.invoicingSettingsNeeded; }
    }
  ],"input-fields":[
      {
        key: 'editPlan',
        label: 'label.plan',
        type: 'list-select',
        items: _vm.subscriptionPlanList.subscriptionPlans,
        itemKey: 'id',
        itemText: 'label',
        itemDescription: 'description',
        itemDisabled: function (subscriptionPlan) { return subscriptionPlan.disabled; }
      }
    ],"item":_vm.subscription,"open-handler":_vm.handleOnOpen,"save-handler":_vm.handleSave,"subtitle":_vm.subtitle}})}
var staticRenderFns = []

export { render, staticRenderFns }