var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-edit-dialog',{attrs:{"id":"product-category","item":_vm.productCategory,"uuid-item":true,"save-handler":_vm.productCategory.save,"delete-handler":_vm.productCategory.delete,"input-fields":[
      {
        key: 'name',
        type: 'text',
        label: 'label.name',
        required: true
      }
    ],"open-handler":_vm.handleOnOpen}})}
var staticRenderFns = []

export { render, staticRenderFns }