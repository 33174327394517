var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-content-card',{attrs:{"title":"label.orders","fetch":_vm.dashboard.fetch}},[_c('t-table',{attrs:{"items":[_vm.dashboard.order],"item-key":"id","item-properties":[
          {
            key: 'today',
            type: 'number'
          },
          {
            key: 'thisWeek',
            type: 'number'
          },
          {
            key: 'thisMonth',
            type: 'number'
          },
          {
            key: 'thisYear',
            type: 'number'
          }
        ],"horizontal":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }