var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-table',{attrs:{"item-properties":[
        {
          key: 'sum'
        },
        {
          key: 'amount',
          type: 'amount'
        },
        {
          key: 'numberOfOrders',
          type: 'number'
        },
        {
          key: 'numberOfProducts',
          type: 'number'
        }
      ],"items":[
        {
          sum: _vm.$t('label.cashPayment'),
          amount: _vm.report.cashPaymentSummary.amount,
          numberOfOrders: _vm.report.cashPaymentSummary.numberOfOrders,
          numberOfProducts: _vm.report.cashPaymentSummary.numberOfProducts
        },
        {
          sum: _vm.$t('label.topupzPayment'),
          amount: _vm.report.topupzPaymentSummary.amount,
          numberOfOrders: _vm.report.topupzPaymentSummary.numberOfOrders,
          numberOfProducts: _vm.report.topupzPaymentSummary.numberOfProducts
        },
        {
          sum: _vm.$t('label.bancontactPayment'),
          amount: _vm.report.bancontactPaymentSummary.amount,
          numberOfOrders: _vm.report.bancontactPaymentSummary.numberOfOrders,
          numberOfProducts: _vm.report.bancontactPaymentSummary.numberOfProducts
        },
        {
          sum: _vm.$t('label.total'),
          amount: _vm.report.totalSummary.amount,
          numberOfOrders: _vm.report.totalSummary.numberOfOrders,
          numberOfProducts: _vm.report.totalSummary.numberOfProducts
        }
      ]}}),_c('t-table',{staticClass:"mt-8",attrs:{"default-sort-ascending":false,"item-properties":[
        {
          key: 'productName',
          header: 'label.product',
          sortable: true
        },
        {
          key: 'count',
          type: 'number',
          sortable: true
        },
        {
          key: 'totalAmount',
          type: 'amount',
          sortable: true
        }
      ],"items":_vm.report.products,"default-sort-property-key":"totalAmount","item-key":"productName"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }