var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-table',{staticStyle:{"display":"inline-block","width":"30%","vertical-align":"top","min-width":"350px","margin-right":"16px"},attrs:{"items":[
        {
          sum: _vm.$t('label.totalAmount'),
          amount: _vm.report.totalAmount
        }
      ],"item-key":"key","item-properties":[
        {
          key: 'sum'
        },
        {
          key: 'amount',
          type: 'amount'
        }
      ]}}),_c('t-table',{staticStyle:{"display":"inline-block","width":"30%","vertical-align":"top","min-width":"350px"},attrs:{"items":[
        {
          sum: _vm.$t('label.numberOfAssignments'),
          count: _vm.report.numberOfAssignments
        },
        {
          sum: _vm.$t('label.numberOfRewards'),
          count: _vm.report.numberOfRewards
        },
        {
          sum: _vm.$t('label.numberOfGrantees'),
          count: _vm.report.numberOfGrantees
        }
      ],"item-key":"key","item-properties":[
        {
          key: 'sum'
        },
        {
          key: 'count',
          type: 'number'
        }
      ]}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":_vm.report.assignments,"item-key":"id","item-properties":[
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true
        },
        {
          key: 'reason',
          sortable: true
        },
        {
          key: 'userId',
          sortable: true
        },
        {
          key: 'amount',
          type: 'amount',
          sortable: true
        }
      ],"default-sort-property-key":"dateTime"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }