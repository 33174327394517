var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-line-chart',{attrs:{"labels":[
      'label.january',
      'label.february',
      'label.march',
      'label.april',
      'label.may',
      'label.june',
      'label.july',
      'label.august',
      'label.september',
      'label.october',
      'label.november',
      'label.december' ],"values":[
      _vm.report.januaryRevenue,
      _vm.report.februaryRevenue,
      _vm.report.marchRevenue,
      _vm.report.aprilRevenue,
      _vm.report.mayRevenue,
      _vm.report.juneRevenue,
      _vm.report.julyRevenue,
      _vm.report.augustRevenue,
      _vm.report.septemberRevenue,
      _vm.report.octoberRevenue,
      _vm.report.novemberRevenue,
      _vm.report.decemberRevenue ],"value-type":"amount"}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":[
        {
          month: 'january',
          monthNumber: 1,
          revenue: _vm.report.januaryRevenue
        },
        {
          month: 'february',
          monthNumber: 2,
          revenue: _vm.report.februaryRevenue
        },
        {
          month: 'march',
          monthNumber: 3,
          revenue: _vm.report.marchRevenue
        },
        {
          month: 'april',
          monthNumber: 4,
          revenue: _vm.report.aprilRevenue
        },
        {
          month: 'may',
          monthNumber: 5,
          revenue: _vm.report.mayRevenue
        },
        {
          month: 'june',
          monthNumber: 6,
          revenue: _vm.report.juneRevenue
        },
        {
          month: 'july',
          monthNumber: 7,
          revenue: _vm.report.julyRevenue
        },
        {
          month: 'august',
          monthNumber: 8,
          revenue: _vm.report.augustRevenue
        },
        {
          month: 'september',
          monthNumber: 9,
          revenue: _vm.report.septemberRevenue
        },
        {
          month: 'october',
          monthNumber: 10,
          revenue: _vm.report.octoberRevenue
        },
        {
          month: 'november',
          monthNumber: 11,
          revenue: _vm.report.novemberRevenue
        },
        {
          month: 'december',
          monthNumber: 12,
          revenue: _vm.report.decemberRevenue
        }
      ],"item-key":"key","item-properties":[
        {
          key: 'month',
          text: function (item) { return _vm.$t('label.' + item.month); },
          value: function (item) { return item.monthNumber; },
          sortable: true
        },
        {
          key: 'revenue',
          type: 'amount',
          header: 'label.amount',
          sortable: true
        }
      ],"default-sort-property-key":"month"}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":_vm.report.products,"item-key":"productName","item-properties":[
        {
          key: 'productName',
          header: 'label.product',
          sortable: true
        },
        {
          key: 'januaryRevenue',
          header: 'label.january',
          type: 'amount',
          sortable: true
        },
        {
          key: 'februaryRevenue',
          header: 'label.february',
          type: 'amount',
          sortable: true
        },
        {
          key: 'marchRevenue',
          header: 'label.march',
          type: 'amount',
          sortable: true
        },
        {
          key: 'aprilRevenue',
          header: 'label.april',
          type: 'amount',
          sortable: true
        },
        {
          key: 'mayRevenue',
          header: 'label.may',
          type: 'amount',
          sortable: true
        },
        {
          key: 'juneRevenue',
          header: 'label.june',
          type: 'amount',
          sortable: true
        },
        {
          key: 'julyRevenue',
          header: 'label.july',
          type: 'amount',
          sortable: true
        },
        {
          key: 'augustRevenue',
          header: 'label.august',
          type: 'amount',
          sortable: true
        },
        {
          key: 'septemberRevenue',
          header: 'label.september',
          type: 'amount',
          sortable: true
        },
        {
          key: 'octoberRevenue',
          header: 'label.october',
          type: 'amount',
          sortable: true
        },
        {
          key: 'novemberRevenue',
          header: 'label.november',
          type: 'amount',
          sortable: true
        },
        {
          key: 'decemberRevenue',
          header: 'label.december',
          type: 'amount',
          sortable: true
        } ],"default-sort-property-key":"productName"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }