var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"default-sort-ascending":false,"item-properties":[
        {
          key: 'date',
          type: 'date',
          sortable: true
        },
        {
          key: 'description',
          sortable: true
        },
        {
          key: 'number',
          sortable: true
        },
        {
          key: 'amountExcludingVat',
          type: 'amount',
          sortable: true
        },
        {
          key: 'amountIncludingVat',
          type: 'amount',
          sortable: true
        },
        {
          key: 'document',
          type: 'icon',
          icon: 'mdi-file-download-outline',
          iconClickHandler: function (invoice) { return invoice.downloadDocument(); },
          sortable: true
        },
        {
          key: 'paid',
          type: 'icon',
          icon: function (invoice) { return invoice.paid ? { image: 'mdi-check-circle', color: 'primary' } : null; },
          sortable: true
        },
        {
          key: 'pay',
          type: 'icon',
          icon: function (invoice) { return invoice.paid ? null : { image: 'mdi-credit-card-outline', color: 'primary' }; },
          iconClickHandler: function (invoice) { return _vm.$dialog.openDialog('invoice-payment', invoice); },
          sortable: true
        },
        {
          key: 'view',
          type: 'icon',
          icon: 'mdi-eye',
          iconClickHandler: function (invoice) { return _vm.$dialog.openDialog('invoice', invoice); },
        }
      ],"items":_vm.invoiceList.invoices,"default-sort-property-key":"date","item-key":"id","search":true}})}
var staticRenderFns = []

export { render, staticRenderFns }