var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-line-chart',{attrs:{"labels":[
      'label.monday',
      'label.tuesday',
      'label.wednesday',
      'label.thursday',
      'label.friday',
      'label.saturday',
      'label.sunday' ],"values":[
      _vm.report.mondaySales,
      _vm.report.tuesdaySales,
      _vm.report.wednesdaySales,
      _vm.report.thursdaySales,
      _vm.report.fridaySales,
      _vm.report.saturdaySales,
      _vm.report.sundaySales ]}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":[
        {
          day: 'monday',
          dayNumber: 1,
          sales: _vm.report.mondaySales
        },
        {
          day: 'tuesday',
          dayNumber: 2,
          sales: _vm.report.tuesdaySales
        },
        {
          day: 'wednesday',
          dayNumber: 3,
          sales: _vm.report.wednesdaySales
        },
        {
          day: 'thursday',
          dayNumber: 4,
          sales: _vm.report.thursdaySales
        },
        {
          day: 'friday',
          dayNumber: 5,
          sales: _vm.report.fridaySales
        },
        {
          day: 'saturday',
          dayNumber: 6,
          sales: _vm.report.saturdaySales
        },
        {
          day: 'sunday',
          dayNumber: 7,
          sales: _vm.report.sundaySales
        } ],"item-key":"key","item-properties":[
        {
          key: 'day',
          text: function (item) { return _vm.$t('label.' + item.day); },
          value: function (item) { return item.dayNumber; },
          sortable: true
        },
        {
          key: 'sales',
          type: 'number',
          header: 'label.count',
          sortable: true
        }
      ],"default-sort-property-key":"day"}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":_vm.report.products,"item-key":"productName","item-properties":[
        {
          key: 'productName',
          header: 'label.product',
          sortable: true
        },
        {
          key: 'mondaySales',
          header: 'label.monday',
          type: 'number',
          sortable: true
        },
        {
          key: 'tuesdaySales',
          header: 'label.tuesday',
          type: 'number',
          sortable: true
        },
        {
          key: 'wednesdaySales',
          header: 'label.wednesday',
          type: 'number',
          sortable: true
        },
        {
          key: 'thursdaySales',
          header: 'label.thursday',
          type: 'number',
          sortable: true
        },
        {
          key: 'fridaySales',
          header: 'label.friday',
          type: 'number',
          sortable: true
        },
        {
          key: 'saturdaySales',
          header: 'label.saturday',
          type: 'number',
          sortable: true
        },
        {
          key: 'sundaySales',
          header: 'label.sunday',
          type: 'number',
          sortable: true
        } ],"default-sort-property-key":"productName"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }