<template>
  <div>
    <t-web-app
      :hide="!tenantSelection.selectedTenant"
      :loading-handler="handleApplicationLoad"
      :menu-items="[
        {
          icon: 'mdi-view-dashboard-outline',
          label: 'label.dashboard',
          route: 'dashboard'
        },
        {
          icon: 'mdi-account-box-multiple',
          label: 'label.users',
          route: 'users'
        },
        {
          icon: 'mdi-cards',
          label: 'label.cards',
          route: 'cards'
        },
        {
          icon: 'mdi-credit-card-plus',
          label: 'label.topups',
          route: 'topups'
        },
        {
          icon: 'mdi-shopping',
          label: 'label.orders',
          route: 'orders'
        },
        {
          icon: 'mdi-swap-horizontal-bold',
          label: 'label.transactions',
          route: 'transactions'
        },
        {
          icon: 'mdi-food-fork-drink',
          label: 'label.products',
          route: 'products'
        },
        {
          icon: 'mdi-point-of-sale',
          label: 'label.devices',
          route: 'devices'
        },
        {
          icon: 'mdi-qrcode-scan',
          label: 'label.qrCodes',
          route: 'qr-codes'
        },
        {
          icon: 'mdi-gift',
          label: 'label.rewards',
          route: 'rewards'
        },
        {
          icon: 'mdi-file-chart-outline',
          label: 'label.reports',
          route: 'reports'
        },
        {
          icon: 'mdi-text-box-check-outline',
          label: 'label.invoices',
          route: 'invoices',
          badge: unpaidInvoiceCount.numberOfInvoices || null
        },
        {
          icon: 'mdi-feather',
          label: 'label.subscription',
          route: 'subscription'
        },
        {
          icon: 'mdi-cog',
          label: 'label.settings',
          route: 'settings'
        }
      ]"
      :title="tenantSelection.tenantName"
      :user-menu-items="[
        {
          icon: 'mdi-store',
          label: 'label.tenants',
          handler: handleTenantsClick
        }
      ]"
    />

    <t-message-dialog
      id="welcome-dialog"
      :action-buttons="[
        {
          label: 'label.logout',
          handler: $authorization.logout,
          color: 'error'
        }
      ]"
      :close-handler="handleWelcomeDialogClose"
      close-label="label.continue"
      message="message.welcomeMessage"
      title="message.welcomeTitle"
    />

    <card-dialog/>

    <device-dialog/>
    <device-link-dialog/>
    <device-link-process-dialog/>
    <device-qr-link-dialog/>

    <invoice-payment-dialog/>
    <invoice-dialog/>

    <order-dialog/>

    <product-dialog/>
    <product-category-dialog/>

    <qr-code-dialog />

    <reward-definition-dialog/>
    <reward-dialog/>

    <invoicing-settings-dialog/>

    <paid-subscription-confirm-dialog/>
    <subscription-dialog/>

    <tenant-selection-dialog/>

    <user-dialog/>
    <user-membership-cancel-dialog/>
    <user-group-dialog/>
    <user-group-select-dialog/>
  </div>
</template>

<script>
import useTenantSelection from '@/app/composables/tenant/useTenantSelection'
import useUnpaidInvoiceCount from '@/app/composables/invoice/useUnpaidInvoiceCount'
import useEvent from '@/lib/composables/useEvent'
import useInvoice from '@/app/composables/invoice/useInvoice'
import $authorization from '@/lib/authorization'
import useRefresh from '@/lib/composables/useRefresh'

export default {
  data: function () {
    return {
      tenantSelection: useTenantSelection(),
      welcomeShown: false,
      unpaidInvoiceCount: useUnpaidInvoiceCount()
    }
  },

  created () {
    useEvent().registerListener('app', 'tenant-select', async () => {
      await this.unpaidInvoiceCount.load()

      const invoicePaymentId = this.$route.query.invoicePaymentId
      if (invoicePaymentId) {
        const invoice = useInvoice()
        await invoice.findById(invoicePaymentId)
        if (invoice.id) {
          this.$dialog.openDialog('invoice-payment', invoice)
        }
      }
    })

    useEvent().registerListener('app', 'tenant-save', async () => {
      await $authorization.refreshUser()
      await this.tenantSelection.load()
    })

    useRefresh().registerListener('app', () => {
      this.unpaidInvoiceCount.loader.backgroundRefresh()
    })
  },

  destroyed () {
    useEvent().unregisterListener('app')
    useRefresh().unregisterListener('app')
  },

  methods: {
    async handleApplicationLoad () {
      await this.tenantSelection.load()
      if (this.tenantSelection.loader.error) {
        return false
      }

      if (this.tenantSelection.tenants.length === 0 && !this.welcomeShown) {
        this.$dialog.openDialog('welcome-dialog')
      } else if (this.tenantSelection.tenants.length === 1) {
        await this.tenantSelection.selectTenant(this.tenantSelection.tenants[0])
      } else {
        this.$dialog.openDialog('tenant-selection')
      }

      return true
    },

    handleTenantsClick () {
      this.tenantSelection.selectTenant(null)
      this.$dialog.openDialog('tenant-selection')
    },

    handleWelcomeDialogClose () {
      this.welcomeShown = true
      this.$dialog.openDialog('tenant-selection')
    }
  }
}
</script>
