var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-page',[_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: function () { return _vm.$dialog.openDialog('reward-definition'); }
      }
    ],"fetch":_vm.rewardDefinitionList.fetch,"title":"label.commonlyUsedDefinitions"}},[_c('reward-definition-table',{attrs:{"reward-definition-list":_vm.rewardDefinitionList}})],1),_c('t-content-card',{attrs:{"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: function (reward) { return _vm.$dialog.openDialog('reward', reward); }
      }
    ],"fetch":_vm.rewardSearch.fetch,"title":"label.rewards"}},[_c('reward-table',{attrs:{"reward-search":_vm.rewardSearch}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }