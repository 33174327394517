var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-form',{ref:"form",attrs:{"form-data":_vm.settings.generalSettings,"has-changes-handler":function (hasChanges) { return _vm.$emit('hasChanges', hasChanges); },"input-fields":[
        {
          key: 'language',
          type: 'select',
          items: [
            {
              value: 'nl',
              text: _vm.$t('label.dutch')
            },
            {
              value: 'fr',
              text: _vm.$t('label.french')
            },
            {
              value: 'en',
              text: _vm.$t('label.english')
            }
          ],
          itemKey: 'value',
          itemText: 'text'
        }
      ]}})}
var staticRenderFns = []

export { render, staticRenderFns }