var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"default-sort-ascending":false,"item-filtering-handler":_vm.rewardSearch.search,"item-properties":[
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true
        },
        {
          key: 'reason',
          sortable: true
        },
        {
          header: 'label.numberOfGrantees',
          value: function (item) { return item.grantees.length; },
          type: 'number'
        },
        {
          key: 'amount',
          header: 'label.amountEach',
          type: 'amount',
          sortable: true
        },
        {
          key: 'grantedByUserId',
          sortable: true
        },
        {
          key: 'assigned',
          type: 'icon',
          icon: function (reward) { return reward.assigned ? { image: 'mdi-check-circle', color: 'primary' } : { image: 'mdi-timer-outline', color: 'primary', tooltip: 'label.waitPeriod' }; },
          sortable: true
        },
        {
          header: 'label.details',
          icon: function (reward) { return reward.assigned === true ? 'mdi-eye' : 'mdi-lead-pencil'; },
          iconClickHandler: function (reward) { return _vm.$dialog.openDialog('reward', reward); }
        }
      ],"items":_vm.rewardSearch,"search":true,"default-sort-property-key":"dateTime","item-key":"id","no-items-label":"message.noRewards"}})}
var staticRenderFns = []

export { render, staticRenderFns }