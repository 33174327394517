var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-selection-dialog',{attrs:{"id":"tenant-selection","cancel-handler":_vm.tenantSelection.cancelSelection,"input-fields":[
      {
        key: 'id',
        type: 'text',
        label: 'label.tenantId',
        availabilityHandler: _vm.handleTenantIdAvailability,
        required: true,
        readOnlyForEdit: true,
        alphanumeric: true,
        lowercase: true
      },
      {
        key: 'name',
        type: 'text',
        label: 'label.name',
        required: true
      }
    ],"item-editable":function (tenant) { return tenant.owner; },"items":_vm.tenantSelection.tenants,"save-handler":_vm.handleSave,"selectionHandler":function (tenant) { return _vm.tenantSelection.selectTenant(tenant); },"item-key":"id","item-text":"name","no-items-label":"message.tenantSelectionEmpty","subtitle":"message.tenantSelection","title":"label.tenants"}})}
var staticRenderFns = []

export { render, staticRenderFns }