var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-line-chart',{attrs:{"labels":[
      'label.january',
      'label.february',
      'label.march',
      'label.april',
      'label.may',
      'label.june',
      'label.july',
      'label.august',
      'label.september',
      'label.october',
      'label.november',
      'label.december' ],"values":[
      _vm.report.januarySales,
      _vm.report.februarySales,
      _vm.report.marchSales,
      _vm.report.aprilSales,
      _vm.report.maySales,
      _vm.report.juneSales,
      _vm.report.julySales,
      _vm.report.augustSales,
      _vm.report.septemberSales,
      _vm.report.octoberSales,
      _vm.report.novemberSales,
      _vm.report.decemberSales ]}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":[
        {
          month: 'january',
          monthNumber: 1,
          sales:_vm.report.januarySales
        },
        {
          month: 'february',
          monthNumber: 2,
          sales:_vm.report.februarySales
        },
        {
          month: 'march',
          monthNumber: 3,
          sales:_vm.report.marchSales
        },
        {
          month: 'april',
          monthNumber: 4,
          sales:_vm.report.aprilSales
        },
        {
          month: 'may',
          monthNumber: 5,
          sales:_vm.report.maySales
        },
        {
          month: 'june',
          monthNumber: 6,
          sales:_vm.report.juneSales
        },
        {
          month: 'july',
          monthNumber: 7,
          sales:_vm.report.julySales
        },
        {
          month: 'august',
          monthNumber: 8,
          sales:_vm.report.augustSales
        },
        {
          month: 'september',
          monthNumber: 9,
          sales:_vm.report.septemberSales
        },
        {
          month: 'october',
          monthNumber: 10,
          sales:_vm.report.octoberSales
        },
        {
          month: 'november',
          monthNumber: 11,
          sales:_vm.report.novemberSales
        },
        {
          month: 'december',
          monthNumber: 12,
          sales:_vm.report.decemberSales
        }
      ],"item-key":"key","item-properties":[
        {
          key: 'month',
          text: function (item) { return _vm.$t('label.' + item.month); },
          value: function (item) { return item.monthNumber; },
          sortable: true
        },
        {
          key: 'sales',
          type: 'number',
          header: 'label.count',
          sortable: true
        }
      ],"default-sort-property-key":"month"}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":_vm.report.products,"item-key":"productName","item-properties":[
        {
          key: 'productName',
          header: 'label.product',
          sortable: true
        },
        {
          key: 'januarySales',
          header: 'label.january',
          type: 'number',
          sortable: true
        },
        {
          key: 'februarySales',
          header: 'label.february',
          type: 'number',
          sortable: true
        },
        {
          key: 'marchSales',
          header: 'label.march',
          type: 'number',
          sortable: true
        },
        {
          key: 'aprilSales',
          header: 'label.april',
          type: 'number',
          sortable: true
        },
        {
          key: 'maySales',
          header: 'label.may',
          type: 'number',
          sortable: true
        },
        {
          key: 'juneSales',
          header: 'label.june',
          type: 'number',
          sortable: true
        },
        {
          key: 'julySales',
          header: 'label.july',
          type: 'number',
          sortable: true
        },
        {
          key: 'augustSales',
          header: 'label.august',
          type: 'number',
          sortable: true
        },
        {
          key: 'septemberSales',
          header: 'label.september',
          type: 'number',
          sortable: true
        },
        {
          key: 'octoberSales',
          header: 'label.october',
          type: 'number',
          sortable: true
        },
        {
          key: 'novemberSales',
          header: 'label.november',
          type: 'number',
          sortable: true
        },
        {
          key: 'decemberSales',
          header: 'label.december',
          type: 'number',
          sortable: true
        } ],"default-sort-property-key":"productName"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }