var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-page',[_c('t-content-card',{attrs:{"title":"label.categories","fetch":_vm.productCategoryList.fetch,"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: function () { return _vm.$dialog.openDialog('product-category'); }
      }
    ]}},[_c('product-category-chips',{attrs:{"product-category-list":_vm.productCategoryList}})],1),_c('t-content-card',{attrs:{"title":"label.products","fetch":_vm.productList.fetch,"actions":[
      {
        icon: 'mdi-plus-circle',
        handler: function () { return _vm.event.fire('product-add', {
          categoryId: _vm.productList.categoryId
        }); }
      }
    ]}},[_c('product-table',{attrs:{"product-list":_vm.productList}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }