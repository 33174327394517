var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"item-properties":[
        {
          key: 'name',
          sortable: true,
        },
        {
          key: 'amount',
          type: 'amount',
          sortable: true
        },
        {
          header: 'label.assign',
          icon: 'mdi-gift',
          iconClickHandler: function (rewardDefinition) { return _vm.$dialog.openDialog('reward', {
            reason: rewardDefinition.name,
            amount: rewardDefinition.amount,
          }); }
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: function (rewardDefinition) { return _vm.$dialog.openDialog('reward-definition', rewardDefinition); }
        }
      ],"items":_vm.rewardDefinitionList.fetch,"default-sort-property-key":"name","item-key":"id","no-items-label":"message.noRewardDefinitions"}})}
var staticRenderFns = []

export { render, staticRenderFns }