var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-confirm-dialog',{attrs:{"id":"user-membership-cancel","confirm-danger":true,"confirm-handler":_vm.user.cancelMembership,"input-fields":[
      {
        key: 'disableCardIds',
        label: 'label.disableCards',
        type: 'checkboxes',
        items: _vm.userCards.items,
        itemKey: 'id',
        itemText: 'formattedId',
        noItemsLabel: 'message.noCards'
      }
    ],"open-handler":_vm.onOpen,"message":"message.cancelMembership","title":"label.cancelMembership"}})}
var staticRenderFns = []

export { render, staticRenderFns }