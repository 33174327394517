export default {
  label: {
    accountBalance: 'Account balance',
    accounts: 'Accounts',
    active: 'Active',
    activity: 'Activity',
    add: 'Add',
    addCard: 'Add card',
    addDocument: 'Add document',
    addReward: 'Add Reward',
    addRewardDefinition: 'Add Reward Definition',
    addUser: 'Add User',
    admin: 'Admin',
    adminEnabled: 'Admin powered',
    administration: 'Administration',
    administrator: 'Administrator',
    administrators: 'Administrator',
    allowedForOrders: 'Allowed for orders',
    allowedForTopups: 'Allowed for topups',
    amount: 'Amount',
    amountEach: 'Amount each',
    amountExcludingVat: 'Amount excluding VAT',
    amountIncludingVat: 'Amount including VAT',
    applicationVersion: 'Application version',
    approve: 'Approve',
    approved: 'Approved',
    april: 'April',
    assign: 'Assign',
    assigned: 'Assigned',
    assignNow: 'Assign now',
    august: 'August',
    bancontactPayment: 'Bancontact payment',
    bancontactPaymentAmount: 'Bancontact payment amount',
    basic: 'Basic',
    beginDate: 'Begin date',
    businessKey: 'Business key',
    cancel: 'Cancel',
    cancelled: 'Cancelled',
    cancelledBalance: 'Cancelled balance',
    cancelMembership: 'Cancel membership',
    cancelProcess: 'Cancel process',
    cardAssignments: 'Assignments',
    cardNumber: 'Card Number',
    cardOrders: 'Orders',
    cards: 'Cards',
    cashAmount: 'Cash amount',
    cashier: 'Cashier',
    cashiers: 'Cashiers',
    cashPayment: 'Cash payment',
    cashPaymentAmount: 'Cash payment amount',
    categories: 'Categories',
    category: 'Category',
    close: 'Close',
    club: 'Club',
    clubs: 'Clubs',
    clubName: 'Club name',
    commonlyUsedDefinitions: 'Commonly used definitions',
    companyName: 'Company name',
    confirm: 'Confirm',
    context: 'Context',
    continue: 'Continue',
    corrections: 'Corrections',
    correctiveAmount: 'Corrective amount',
    count: 'Count',
    csvFile: 'Received CSV file',
    currentInvoicingBeginDate: 'Current invoicing begin date',
    currentInvoicingEndDate: 'Current invoicing end date',
    currentInvoicingOrders: 'Current invoicing orders',
    currentMonth: 'Current month',
    currentMonthOrders: 'Current month orders',
    currentQuarter: 'Current quarter',
    currentWeek: 'Current week',
    currentYear: 'Current year',
    custom: 'Custom',
    dashboard: 'Dashboard',
    date: 'Date',
    dateTime: 'Timestamp',
    day: 'Day',
    debitAmount: 'Debit amount',
    december: 'December',
    definitions: 'Definitions',
    delete: 'Delete',
    deletedUser: 'Deleted user',
    description: 'Description',
    details: 'Details',
    devices: 'Devices',
    diagram: 'Diagram',
    disableCards: 'Disable cards',
    disabled: 'Disabled',
    disabledMemberships: 'Disabled memberships',
    document: 'Document',
    downloadCardsCsv: 'Download cards CSV',
    downloadCsv: 'Download CSV',
    downloadDocument: 'Download document',
    dutch: 'Dutch',
    edit: 'Edit',
    editUser: 'Edit User',
    email: 'Email',
    emailVerified: 'Email verified',
    enabled: 'Enabled',
    endDate: 'End date',
    endTime: 'End time',
    english: 'English',
    error: 'Error',
    execute: 'Execute',
    favorite: 'Favorite',
    february: 'February',
    finished: 'Finished',
    firstName: 'First name',
    free: 'Free',
    freeOrders: 'Free orders',
    french: 'French',
    friday: 'Friday',
    fromDate: 'From date',
    general: 'General',
    generateQrCode: 'Generate QR code',
    grantedByUserId: 'Granted by',
    houseNumber: 'House number',
    id: 'Id',
    inactive: 'Not active',
    incident: 'Incident',
    incidents: 'Incidents',
    includeFinished: 'Include finished',
    initCards: 'Initialize cards',
    initialized: 'Initialized',
    initiator: 'Initiator',
    instances: 'Instances',
    instancesWithIncident: 'Instances with incident',
    invoice: 'Invoice',
    invoices: 'Invoices',
    invoicing: 'Invoicing',
    invoicingSettings: 'Invoicing settings',
    january: 'January',
    july: 'July',
    june: 'June',
    key: 'Key',
    language: 'Language',
    lastName: 'Last name',
    lines: 'Lines',
    linkDevice: 'Link device',
    locale: 'Locale',
    logo: 'Logo',
    logout: 'Logout',
    manualEntry: 'Manual entry',
    march: 'March',
    may: 'May',
    members: 'Members',
    membershipCancellation: 'Membership cancellation',
    membershipCancellations: 'Membership cancellations',
    memberships: 'Memberships',
    message: 'Message',
    minimumOrderAmount: 'Minimum order amount',
    minimumTopupAmount: 'Minimum topup amount',
    model: 'Model',
    modify: 'Modify',
    modifyDocument: 'Modify document',
    mollieApiKey: 'Mollie API key',
    monday: 'Monday',
    monitorProcess: 'Continue monitoring',
    month: 'Month',
    municipality: 'Municipality',
    name: 'Name',
    new: 'New',
    newDevice: 'Register new device',
    nextPlan: 'Next plan',
    nickname: 'Nickname',
    november: 'November',
    number: 'Number',
    numberOfAssignments: 'Number of assignments',
    numberOfCancellations: 'Number of cancellations',
    numberOfCards: 'Number of cards',
    numberOfCorrections: 'Number of corrections',
    numberOfGrantees: 'Number of grantees',
    numberOfOrders: 'Number of orders',
    numberOfProducts: 'Number of products',
    numberOfRewards: 'Number of rewards',
    numberOfTopups: 'Number of topups',
    numberOfUsers: 'Number of users',
    october: 'October',
    ok: 'Ok',
    online: 'Online',
    onlineCashiers: 'Online cashiers',
    onlineDevices: 'Online devices',
    onlineOrders: 'Online orders',
    onlinePayments: 'Online payments',
    onlineScanners: 'Online scanners',
    order: 'Order',
    orderDate: 'Order date',
    orderItems: 'Order items',
    orderReference: 'Order reference',
    orderReport: 'Order report',
    orders: 'Orders',
    overallAdmin: 'Overall admin',
    overview: 'Overview',
    paid: 'Paid',
    pay: 'Pay',
    payment: 'Payment',
    paymentMethod: 'Payment method',
    paymentQrCode: 'Payment QR code',
    period: 'Period',
    periods: 'Periods',
    pincode: 'Pincode',
    plan: 'Plan',
    postalCode: 'Postal code',
    previousMonth: 'Previous month',
    previousMonthOrders: 'Previous month orders',
    previousQuarter: 'Previous quarter',
    previousWeek: 'Previous week',
    previousYear: 'Previous year',
    price: 'Price',
    processedDate: 'Processed Date',
    product: 'Product',
    products: 'Products',
    productSalesPerMonth: 'Product sales per month',
    productSalesPerWeekday: 'Product sales per weekday',
    profile: 'Profile',
    provider: 'Provider',
    quantity: 'Quantity',
    qrCodeAssignments: 'QR code assignments',
    qrCodeOrders: 'QR code orders',
    qrCodes: 'QR codes',
    reason: 'Reason',
    receivedDate: 'Received Date',
    reference: 'Reference',
    remainingFreeOrders: 'Remaining free orders',
    renewTermsAndConditions: 'Renew terms and conditions',
    report: 'Report',
    reports: 'Reports',
    reportType: 'Report type',
    request: 'Request',
    resend: 'Resend',
    resetPin: 'Reset pincode to \'1111\'',
    retry: 'Retry',
    revenuePerMonth: 'Revenue per month',
    revenuePerWeekday: 'Revenue per weekday',
    reward: 'Reward',
    rewards: 'Rewards',
    runningOrders: 'Running orders',
    saturday: 'Saturday',
    save: 'Save',
    scanner: 'Scanner',
    scannerId: 'Scanner Id',
    scanners: 'Scanners',
    search: 'Search',
    september: 'September',
    serialNumber: 'Serial Number',
    settings: 'Settings',
    shortenedClubName: 'Shortened club name',
    start: 'Start',
    startTime: 'Start time',
    status: 'Status',
    stop: 'Stop',
    street: 'Street',
    subscription: 'Subscription',
    subscriptionDate: 'Subscription date',
    subscriptionPlan_BASIC: 'Basic',
    subscriptionPlan_CUSTOM: 'Custom',
    subscriptionPlan_FREE: 'Free',
    subscriptions: 'Subscriptions',
    sum: 'Sum',
    sunday: 'Sunday',
    suspension: 'Suspension',
    tenant: 'Club',
    tenantAdmin: 'Club administrator',
    tenantId: 'Club ID',
    tenants: 'Clubs',
    terminate: 'Beëindigen',
    thisWeek: 'This week',
    thisMonth: 'This month',
    thisYear: 'This year',
    thursday: 'Thursday',
    timezone: 'Timezone',
    toApprove: 'To approve',
    toDate: 'To date',
    today: 'Today',
    toOnlinePayment: 'Naar online betaling',
    toPay: 'To pay',
    toPickup: 'To pickup',
    toPrepare: 'To prepare',
    toServe: 'To serve',
    topup: 'Topup',
    topups: 'Topups',
    topupzPayment: 'Topupz payment',
    topupzPaymentAmount: 'Topupz payment amount',
    total: 'Total',
    totalAmount: 'Total amount',
    transactions: 'Transactions',
    tuesday: 'Tuesday',
    type: 'Type',
    unassigned: 'Unassigned',
    unassignedCards: 'Unassigned cards',
    unlink: 'Unlink',
    unverified: 'Unverified',
    user: 'User',
    userCount: 'Number of users',
    userGroup: 'User group',
    userGroups: 'User groups',
    userId: 'User ID',
    username: 'Username',
    users: 'Users',
    variables: 'Variables',
    vatNumber: 'VAT number',
    version: 'Version',
    view: 'View',
    viewReward: 'View Reward',
    waitPeriod: 'Wait period',
    wednesday: 'Wednesday',
    yesterday: 'Yesterday'
  },
  message: {
    cancelMembership: 'Cancelling a membership will cause the user to lose the content of his account. All cards that are still linked to the user will be unlinked and optionally disabled.',
    card_already_added: 'Card Already Presented!',
    card_already_linked: 'Card Already Linked!',
    card_initialized: 'OK',
    cardOrderCsvInvalid: 'The cards CSV has an invalid format',
    cardOrderCsvDuplicateData: 'The cards CSV contains existing data',
    cardOrderCsvQuantityMismatch: 'The cards CSV doesn\'t have the expected quantity',
    cardOrderCsvDataMismatch: 'The cards CSV doesn\'t have the expected cards',
    cardScanWaiting: 'Please scan a new card',
    confirmDelete: 'Are you sure you want to delete this data?',
    confirmIrrevocableAction: 'This action cannot be undone. Are you sure you want to continue?',
    confirmPaidSubscription: 'You have selected a paid subscription plan which will be billed on a monthly basis. Do you want to continue?',
    confirmRenewTermsAndConditions: 'All users will be asked to accept the terns and conditions the next time they log in.',
    confirmUnlink: 'Are you sure to unlink this device from this club? Is the club aware that there is possible data loss and that (s)he needs to link the device again?',
    confirmUnlinkCard: 'Are you sure to unlink this card for this user?',
    correctionReport: 'Summary of the corrections of orders and topups',
    deviceBusy: 'The device is busy',
    deviceLinkedToOtherTenant: 'The device is linked to another club',
    deviceLinkingDenied: 'Confirmation denied on the device',
    deviceLinkingDone: 'The device is linked successfully',
    deviceLinkingTimeout: 'No confirmation given on device',
    deviceLinkingWaiting: 'Please confirm on the device',
    deviceNotAdoptable: 'The device is not in an adoptable state',
    editReward: 'This reward can still be modified because it hasn\'t been assigned yet. This happens after a wait period of 1 hour after creation, but you can also assign it immediately by using the \'Assign now\' action',
    initCardsDone: 'All cards have been initialised',
    invoicingInformationRequiredForPaidServices: 'To be able to use paid Topupz service your invoicing information has to be filled in',
    linkDeviceInfo: 'Confirm camara usage in the browser and present the device with the showed QR-code towards the camera or enter the serial number manually',
    loadingError: 'An error occurred while loading the application',
    membershipCancellationReport: 'Overview of the membership cancellations and the cancelled account balances',
    newReward: 'By adding a reward you are giving the entered amount to all the selected users. The assignment of the reward will be done after a wait period of 1 hour, after which you will not be able to modify or delete the reward',
    nextPlan: 'On {nextPlanDate} your subscription will be changed to plan \'{nextPlan}\'',
    noAccount: 'In order to enjoy the Topupz experience a card should be added. In case you didn\'t receive one yet, please ask your club administrator',
    noAccounts: 'No accounts found',
    noAdmin: 'Your are not an administrator',
    noCardAssignments: 'No assignments found',
    noCardOrders: 'No orders found',
    noCards: 'No cards found',
    noDataFound: 'No data was found',
    noDevices: 'No devices found',
    noInvoices: 'You have no invoice',
    noOrders: 'No orders found',
    noProducts: 'You have not added any products yet',
    noRewardDefinitions: 'You have not created any commonly used definitions yet',
    noRewards: 'No rewards found',
    noTenants: 'No clubs found',
    noTopups: 'No topups found',
    noTransactions: 'No transactions found',
    noUserGroups: 'You have not created any user groups yet',
    noUsers: 'No users found',
    onlineOrders: 'Allows customers to place orders online and pay in advance\nThese orders are then visible on the cashiers, where they can be treated further\nYou can order QR codes that your customers can scan to arrive on the order page',
    onlinePayments: 'To accept online Bancontact payments Topupz can use the payment platform Mollie\nA QR code is shown to the customer so he can execute the payment in his Bancontact application\nU can activate online payments for orders and topups separately and set an optional minumum amount\nThese settings require you to have an active Mollie account, for more information you can visit mollie.com',
    orderReport: 'Revenue and number of products sold, in total and per product',
    productSalesPerMonthReport: 'Number of products sold grouped by month, in total als per product',
    productSalesPerWeekdayReport: 'Number of products sold grouped by day, in total als per product',
    qrCodeOrderCsvInvalid: 'The CSV contains invalid QR code data',
    qrCodeOrderCsvDuplicateData: 'The CSV contains existing QR codes',
    qrCodeOrderCsvQuantityMismatch: 'The CSV doesn\'t have the expected number of QR codes',
    qrCodeOrderCsvDataMismatch: 'The CSV doesn\'t contain the expected QR codes',
    qrCodeOrderDeleteAssigned: 'The QR code order cannot be deleted because a QR code is still assigned to a club',
    registerNewDeviceInfo: 'Confirm camara usage in the browser and present the device with the showed QR-code towards the camera or enter the serial number manually',
    revenuePerMonthReport: 'Revenue grouped by month, in total als per product',
    revenuePerWeekdayReport: 'Revenue grouped by day, in total als per product',
    rewardReport: 'Amount of assigned rewards, in total and per assignment',
    scannerBusy: 'Scanner is busy',
    scannerGone: 'Scanner is gone',
    scanQrCode: 'Scan the QR code with your camera to order',
    selectUserGroup: 'Select a user group',
    subscriptionPlan_BASIC: '€35 per month for 1000 orders\n€5 per additional package of 1000 order, not transferable',
    subscriptionPlan_CUSTOM: 'Custom subscription according to the agreed terms',
    subscriptionPlan_FREE: 'Limited to 10 orders per day\nNo additional orders possible',
    subscriptionPlanChangeDelay: 'You can choose to change your subscription plan now\nThe change will be in effect after the end of your current invoicing period',
    tenantSelection: 'Select a club for which you are the owner or administrator',
    tenantSelectionEmpty: 'You han\'t created any clubs yet',
    topupReport: 'Total amount and number of topups',
    unauthorized: 'You are not authorized to use this application',
    unknown_card: 'Unknown Card!',
    unknownDevice: 'This is not a known device',
    userDisabledByTopupz: 'The user has been disabled by Topupz',
    viewReward: 'This reward can no longer be modified because it has already been assigned',
    welcome: 'Welcome to Topupz!',
    welcomeMessage: 'Begin by creating a club to be able to enjoy Topupz',
    welcomeTitle: 'Welcome to Topupz for clubs'
  },
  parametrized: {
    fromTo: 'From {from} to {to}'
  },
  status: {
    canceled_by_user: 'Canceled by user',
    creating_online_payment: 'Creating online payment',
    done: 'Done',
    online_payment_canceled: 'Online payment canceled',
    online_payment_failed: 'Online payment failed',
    online_payment_timeout: 'Online payment timeout',
    paid: 'Done',
    waiting_for_online_payment: 'Waiting for online payment',
    waiting_for_payment: 'Waiting for payment'
  },
  validation: {
    cardDisabled: 'Card disabled',
    fieldRequired: 'This field is required',
    invalidCardId: 'Invalid card ID',
    invalidUuid: 'This is not a valid identification number',
    invalidValue: 'This value is invalid',
    membershipDisabled: 'Membership disabled',
    minValue: 'Value should be at least equal to {minValue}',
    mollieApiKeyRequired: 'This value is required if you want to be able to accept online payments',
    valueNotAvailable: 'This value is not available'
  }
}
