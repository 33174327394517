var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-process-dialog',{attrs:{"id":"invoice-payment","title":"label.payment","statuses":[
      {
        code: 'WAITING_FOR_PAYMENT'
      },
      {
        code: 'CREATING_ONLINE_PAYMENT'
      },
      {
        code: 'WAITING_FOR_ONLINE_PAYMENT',
        action: true,
        actions: [
          {
            handler: _vm.invoice.checkout,
            label: 'label.toOnlinePayment'
          }
        ],
        image: _vm.invoice.process && _vm.invoice.process.qrCode
      },
      {
        code: 'PAID',
        success: true
      },
      {
        code: 'ONLINE_PAYMENT_FAILED',
        actions: [
          {
            handler: _vm.invoice.pay,
            label: 'label.pay'
          }
        ],
        image: 'mdi-close-circle'
      },
      {
        code: 'ONLINE_PAYMENT_TIMEOUT',
        actions: [
          {
            handler: _vm.invoice.pay,
            label: 'label.pay'
          }
        ],
        image: 'mdi-close-circle'
      },
      {
        code: 'ONLINE_PAYMENT_CANCELED',
        actions: [
          {
            handler: _vm.invoice.pay,
            label: 'label.pay'
          }
        ],
        image: 'mdi-close-circle'
      }
    ],"status-code":_vm.invoice.process && _vm.invoice.process.status,"open-handler":_vm.handleDialogOpen,"close-handler":_vm.handleDialogClose}})}
var staticRenderFns = []

export { render, staticRenderFns }