var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-table',{attrs:{"item-properties":[
        {
          key: 'sum'
        },
        {
          key: 'amount',
          type: 'amount'
        },
        {
          key: 'numberOfTopups',
          type: 'number'
        }
      ],"items":[
        {
          sum: _vm.$t('label.cashPayment'),
          amount: _vm.report.cashPaymentSummary.amount,
          numberOfTopups: _vm.report.cashPaymentSummary.numberOfTopups
        },
        {
          sum: _vm.$t('label.bancontactPayment'),
          amount: _vm.report.bancontactPaymentSummary.amount,
          numberOfTopups: _vm.report.bancontactPaymentSummary.numberOfTopups
        },
        {
          sum: _vm.$t('label.total'),
          amount: _vm.report.totalSummary.amount,
          numberOfTopups: _vm.report.totalSummary.numberOfTopups
        }
      ]}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }