var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"item-filtering-handler":_vm.userSearch.search,"item-properties":[
        {
          key: 'id',
          header: 'label.username',
          sortable: true
        },
        {
          key: 'firstName',
          sortable: true
        },
        {
          key: 'lastName',
          sortable: true
          },
        {
          key: 'email',
          sortable: true
        },
        {
          key: 'disabled',
          type: 'icon',
          icon: function (user) { return user.disabled || user.disabledForTenant ? { image: 'mdi-account-off', color: 'error' } : null; },
          sortable: true
        },
        {
          key: 'admin',
          header: 'label.administrator',
          type: 'icon',
          icon: function (user) { return user.admin ? { image: 'mdi-check-circle', color: 'primary' } : null; },
          sortable: true
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: function (user) { return _vm.$dialog.openDialog('user', user); }
        }
      ],"items":_vm.userSearch,"search":true,"default-sort-property-key":"id","item-key":"id","no-items-label":"message.noUsers"}})}
var staticRenderFns = []

export { render, staticRenderFns }