var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"items":_vm.deviceList.items,"item-key-property":"id","item-properties":[
        {
          key: 'type',
          type: 'icon',
          icon: function (device) {
            switch (device.type) {
              case 'CASHIER': return {
                image: 'mdi-cash-register',
                tooltip: 'label.cashier'
              }
              case 'SCANNER': return {
                image: 'mdi-credit-card-scan',
                tooltip: 'label.scanner'
              }
            }
          },
          sortable: true
        },
        {
          key: 'id',
          header: 'label.serialNumber',
          sortable: true
        },
        {
          key: 'name',
          sortable: true
        },
        {
          key: 'model',
          header: 'label.model',
          sortable: true
        },
        {
          key: 'formattedPaymentQrCodeId',
          header: 'label.paymentQrCode',
          sortable: true
        },
        {
          key: 'online',
          type: 'icon',
          icon: function (device) { return device.online ? { image: 'mdi-check-circle', color: 'primary' } : null; },
          sortable: true,
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: function (device) { return _vm.$dialog.openDialog('device', device); }
        }
      ],"search":true,"default-sort-property-key":"online","no-items-label":"message.noDevices"}})}
var staticRenderFns = []

export { render, staticRenderFns }