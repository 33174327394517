var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-table',{staticStyle:{"display":"inline-block","width":"60%","vertical-align":"top","min-width":"350px","margin-right":"16px"},attrs:{"items":[
        {
          sum: _vm.$t('label.orders'),
          numberOfCorrections: _vm.report.numberOfOrderCorrections,
          correctiveAmount: _vm.report.correctiveOrderAmount
        },
        {
          sum: _vm.$t('label.topups'),
          numberOfCorrections: _vm.report.numberOfTopupCorrections,
          correctiveAmount: _vm.report.correctiveTopupAmount
        },
        {
          sum: _vm.$t('label.total'),
          numberOfCorrections: _vm.report.numberOfCorrections,
          correctiveAmount: _vm.report.correctiveAmount
        }
      ],"item-key":"key","item-properties":[
        {
          key: 'sum'
        },
        {
          key: 'numberOfCorrections',
          type: 'number'
        },
        {
          key: 'correctiveAmount',
          type: 'amount'
        }
      ]}}),_c('t-table',{staticClass:"mt-8",attrs:{"items":_vm.report.corrections,"item-key":"id","item-properties":[
        {
          key: 'dateTime',
          type: 'date-time',
          sortable: true
        },
        {
          key: 'type',
          type: 'icon',
          icon: function (correction) {
            switch (correction.type) {
              case 'ORDER': return {
                image: 'mdi-shopping',
                tooltip: 'label.order'
              }
              case 'TOPUP': return {
                image: 'mdi-credit-card-plus',
                tooltip: 'label.topup'
              }
            }
          },
          sortable: true
        },
        {
          key: 'amount',
          type: 'amount',
          sortable: true
        }
      ],"default-sort-property-key":"dateTime","default-sort-ascending":false}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }