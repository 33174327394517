var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"items":_vm.productList.products,"item-key":"id","item-properties":[
        {
          key: 'favorite',
          type: 'icon',
          icon: function (product) { return product.favorite ? { image: 'mdi-heart', color: 'primary' } : 'mdi-heart-outline'; },
          iconClickHandler: function (product) { return product.toggleFavorite(); }
        },
        {
          key: 'logo',
          type: 'logo'
        },
        {
          key: 'name'
        },
        {
          key: 'price',
          type: 'amount'
        },
        {
          key: 'disabled',
          type: 'icon',
          icon: function (product) { return product.disabled ? { image: 'mdi-food-off', color: 'error' } : null; }
        },
        {
          header: 'label.edit',
          icon: 'mdi-lead-pencil',
          iconClickHandler: function (product) { return _vm.event.fire('product-edit', product); }
        }
      ],"drag-data-type":"product","drop-data-types":['product'],"drop-handler":_vm.productDropHandler,"no-items-label":"message.noProducts"}})}
var staticRenderFns = []

export { render, staticRenderFns }