var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-form',{ref:"form",attrs:{"form-data":_vm.settings.onlinePaymentSettings,"has-changes-handler":function (hasChanges) { return _vm.$emit('hasChanges', hasChanges); },"input-fields":[
        {
          key: 'mollieApiKey',
          type: 'text'
        },
        {
          key: 'allowedForOrders',
          type: 'switch'
        },
        {
          key: 'allowedForTopups',
          type: 'switch'
        },
        {
          key: 'minimumOrderAmount',
          type: 'amount'
        },
        {
          key: 'minimumTopupAmount',
          type: 'amount'
        }
      ],"validation-handler":_vm.handleOnlinePaymentSettingsValidation}})}
var staticRenderFns = []

export { render, staticRenderFns }