var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('t-web-app',{attrs:{"hide":!_vm.tenantSelection.selectedTenant,"loading-handler":_vm.handleApplicationLoad,"menu-items":[
      {
        icon: 'mdi-view-dashboard-outline',
        label: 'label.dashboard',
        route: 'dashboard'
      },
      {
        icon: 'mdi-account-box-multiple',
        label: 'label.users',
        route: 'users'
      },
      {
        icon: 'mdi-cards',
        label: 'label.cards',
        route: 'cards'
      },
      {
        icon: 'mdi-credit-card-plus',
        label: 'label.topups',
        route: 'topups'
      },
      {
        icon: 'mdi-shopping',
        label: 'label.orders',
        route: 'orders'
      },
      {
        icon: 'mdi-swap-horizontal-bold',
        label: 'label.transactions',
        route: 'transactions'
      },
      {
        icon: 'mdi-food-fork-drink',
        label: 'label.products',
        route: 'products'
      },
      {
        icon: 'mdi-point-of-sale',
        label: 'label.devices',
        route: 'devices'
      },
      {
        icon: 'mdi-qrcode-scan',
        label: 'label.qrCodes',
        route: 'qr-codes'
      },
      {
        icon: 'mdi-gift',
        label: 'label.rewards',
        route: 'rewards'
      },
      {
        icon: 'mdi-file-chart-outline',
        label: 'label.reports',
        route: 'reports'
      },
      {
        icon: 'mdi-text-box-check-outline',
        label: 'label.invoices',
        route: 'invoices',
        badge: _vm.unpaidInvoiceCount.numberOfInvoices || null
      },
      {
        icon: 'mdi-feather',
        label: 'label.subscription',
        route: 'subscription'
      },
      {
        icon: 'mdi-cog',
        label: 'label.settings',
        route: 'settings'
      }
    ],"title":_vm.tenantSelection.tenantName,"user-menu-items":[
      {
        icon: 'mdi-store',
        label: 'label.tenants',
        handler: _vm.handleTenantsClick
      }
    ]}}),_c('t-message-dialog',{attrs:{"id":"welcome-dialog","action-buttons":[
      {
        label: 'label.logout',
        handler: _vm.$authorization.logout,
        color: 'error'
      }
    ],"close-handler":_vm.handleWelcomeDialogClose,"close-label":"label.continue","message":"message.welcomeMessage","title":"message.welcomeTitle"}}),_c('card-dialog'),_c('device-dialog'),_c('device-link-dialog'),_c('device-link-process-dialog'),_c('device-qr-link-dialog'),_c('invoice-payment-dialog'),_c('invoice-dialog'),_c('order-dialog'),_c('product-dialog'),_c('product-category-dialog'),_c('qr-code-dialog'),_c('reward-definition-dialog'),_c('reward-dialog'),_c('invoicing-settings-dialog'),_c('paid-subscription-confirm-dialog'),_c('subscription-dialog'),_c('tenant-selection-dialog'),_c('user-dialog'),_c('user-membership-cancel-dialog'),_c('user-group-dialog'),_c('user-group-select-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }