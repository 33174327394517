var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"items":_vm.subscriptionPeriodList.subscriptionPeriods,"item-key":"id","item-properties":[
        {
          key: 'beginDate',
          type: 'date'
        },
        {
          key: 'endDate',
          type: 'date'
        },
        {
          key: 'plan',
          value: function (subscriptionPeriod) { return _vm.$t('label.subscriptionPlan_' + subscriptionPeriod.plan); }
        } ],"default-sort-property-key":"beginDate","default-sort-ascending":false}})}
var staticRenderFns = []

export { render, staticRenderFns }