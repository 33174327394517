var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('t-table',{attrs:{"items":[_vm.subscription],"item-key":"tenantId","item-properties":[
        {
          key: 'plan',
          value: function (subscription) { return _vm.$t('label.subscriptionPlan_' + subscription.plan); }
        },
        {
          key: 'subscriptionDate',
          type: 'date'
        },
        {
          key: 'currentInvoicingBeginDate',
          type: 'date',
          visible: function () { return _vm.subscription.plan !== 'FREE'; }
        },
        {
          key: 'currentInvoicingEndDate',
          type: 'date',
          visible: function () { return _vm.subscription.plan !== 'FREE'; }
        },
        {
          key: 'currentInvoicingOrders',
          type: 'number',
          visible: function () { return _vm.subscription.plan !== 'FREE'; }
        },
        {
          key: 'remainingFreeOrders',
          type: 'number',
          visible: function () { return _vm.subscription.plan === 'FREE'; }
        }
      ],"horizontal":true}})}
var staticRenderFns = []

export { render, staticRenderFns }